<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      jurisdiction: [],
      ismobile: false,
      orderpage: 1,
      orderarray: [],
      devicespage: 1,
      devicesearch: "",
      ordersearch: "",
      orderpaystatus: -1,
      orderpaytype: -1,
      ordertimes: [],
      pickerOptions: {},
      datacount: 0,
      allpage: 0,
      activeapp: [],
      loading: true,
      empty: true,
    };
  },
  mounted() {
    this.title = this.$t("menuitems.devices.orders");
    this.items = [
      {
        text: this.$t("menuitems.devices.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.devices.orders"),
        active: true,
      },
    ];
    //   this.getdevicelist()
    this.jurisdiction = JSON.parse(sessionStorage.getItem("jurisdiction"));
    this.activeapp = JSON.parse(sessionStorage.getItem("apps"));
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    let yy = new Date().getFullYear();
    let mm = new Date().getMonth();
    let dd = new Date().getDate();
    let nextday = dd + 1;
    this.ordertimes = [yy + "/" + mm + "/" + dd, yy + "/" + mm + "/" + nextday];
    this.pickerOptions = {
      shortcuts: [
        {
          text: this.$t("device.orders.oneday"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: this.$t("device.orders.oneweek"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: this.$t("device.orders.onemonth"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: this.$t("device.orders.threemonth"),
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
    };
    this.getorderlist();
  },
  computed: {
    searchinorderlist() {
      var paycode = {
        wxpay: 3,
        alipay: 2,
      };
      var datas = this.orderarray.filter((value) => {
        return (
          !this.ordersearch ||
          value.ED_id.toLowerCase().includes(
            this.ordersearch.trim().toLowerCase()
          ) ||
          !this.ordersearch ||
          value.ED_name.toLowerCase().includes(
            this.ordersearch.trim().toLowerCase()
          ) ||
          !this.ordersearch ||
          value.sn.toLowerCase().includes(this.ordersearch.trim().toLowerCase())
        );
      });
      if (this.orderpaystatus >= 0) {
        if (
          this.orderpaystatus == 0 ||
          this.orderpaystatus == 1 ||
          this.orderpaystatus == 3
        ) {
          datas = datas.filter((value) => {
            return value.status == this.orderpaystatus;
          });
        }
      }
      if (this.orderpaytype >= 0) {
        datas = datas.filter((value) => {
          return value.payway == this.orderpaytype;
        });
      }
      return datas;
    },
  },
  methods: {
    getorderlist: function () {
      var that = this;
      var userinfo = JSON.parse(localStorage.getItem("user"));
      that.orderarray = [];
      var date = that.ordertimes[0] + "," + that.ordertimes[1];
      that.orderlistloading = true;
      that.$axios
        .post(
          "https://admin.aicoiot.beer/api.json",
          {
            action: "orderlistv2",
            page: that.orderpage,
            date: date,
          },
          {
            headers: { Openid: userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          var data = response.data;
          that.orderarray = data.data;
          that.datacount = data.count;
          that.allpage = data.allpage;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    logdata: function (route, id) {
      var that = this;
      if (route == "order") {
        that.$toast.loading({
          message: that.$t("global.loading.readingv2"),
          forbidClick: true,
        });
        that.$axios.get("https://doc.aic.pet/" + id + ".orderlog").then((response)=>{
          that.$toast.clear();
          window.location.href = response.data.file
        })
      }
    },
  },
  filters: {
    formatTimer: function (value) {
      value = value * 1000;
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" v-loading="loading">
          <div
            class="card-body"
            v-if="jurisdiction.indexOf('orders-list-search') >= 0"
          >
            <div class="row">
              <p>
                <a
                  href="javascript:;"
                  class="btn btn-outline-primary btn-icon-text"
                  @click="getorderlist()"
                >
                  搜索
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-outline-info btn-icon-text"
                  @click="downloadorderfile()"
                >
                  <i class="fa fa-file-excel-o"></i>
                  下载
                </a>
              </p>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div>
                  <label for="orderdays">{{
                    $t("device.orders.ordertime")
                  }}</label>
                  <div class="row">
                    <el-date-picker
                      v-model="ordertimes"
                      type="daterange"
                      align="right"
                      unlink-panels
                      format="yyyy/MM/dd"
                      value-format="yyyy/MM/dd"
                      :range-separator="$t('device.orders.to')"
                      :start-placeholder="$t('device.orders.begindate')"
                      :end-placeholder="$t('device.orders.enddate')"
                      :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div>
                  <label for="orderdays">{{
                    $t("device.orders.orderstatus")
                  }}</label>
                  <div class="row">
                    <select
                      name="paystatus"
                      class="form-control"
                      v-model="orderpaystatus"
                    >
                      <option value="-1">
                        {{ $t("device.orders.allstatus") }}
                      </option>
                      <option value="0">{{ $t("device.orders.nopay") }}</option>
                      <option value="1">
                        {{ $t("device.orders.orderpay") }}
                      </option>
                      <option value="3">
                        {{ $t("device.orders.refuntrue") }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div>
                  <label for="orderdays">{{
                    $t("device.orders.paytype")
                  }}</label>
                  <div class="row">
                    <select
                      name="paytype"
                      class="form-control"
                      v-model="orderpaytype"
                    >
                      <option value="-1">
                        {{ $t("device.orders.allstatus") }}
                      </option>
                      <option value="0">
                        {{ $t("device.orders.coinpay") }}
                      </option>
                      <option value="1">
                        {{ $t("device.orders.useramount") }}
                      </option>
                      <option value="2">
                        {{ $t("device.orders.alipay") }}
                      </option>
                      <option value="3">{{ $t("device.orders.wxpay") }}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div>
                  <label for="keyword">{{ $t("device.orders.keyword") }}</label>
                  <div class="row">
                    <input
                      type="text"
                      class="form-control"
                      v-model="ordersearch"
                      :placeholder="$t('device.orders.searchorder')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-body"
            style="overflow-x: auto; overflow-y: hidden"
            v-if="!ismobile"
          >
            <table id="deviceslist_table" class="table table-striped">
              <thead>
                <tr>
                  <th class="sortStyle">{{ $t("device.orders.orderid") }}</th>
                  <th class="sortStyle">{{ $t("device.orders.goodsname") }}</th>
                  <th class="sortStyle">
                    {{ $t("device.orders.salsevalues") }}ML
                  </th>
                  <th class="sortStyle">
                    {{ $t("device.orders.orderprice") }}
                  </th>
                  <th class="sortStyle">{{ $t("device.orders.paytype") }}</th>
                  <th class="sortStyle">
                    {{ $t("device.orders.orderstatus") }}
                  </th>
                  <th class="sortStyle">{{ $t("device.orders.ordertime") }}</th>
                  <th class="sortStyle">{{ $t("device.orders.deviceid") }}</th>
                  <th class="sortStyle">{{ $t("device.orders.action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!searchinorderlist">
                  <td>
                    <el-empty
                      :description="$t('device.orders.nodata')"
                    ></el-empty>
                  </td>
                </tr>
                <tr
                  v-for="(val, idx) in searchinorderlist"
                  :class="'device' + val.id"
                  :key="val.id"
                >
                  <td>
                    <span :id="val.sn">{{ val.shid }}</span>
                    <b-tooltip :target="val.sn">{{ val.sn }}</b-tooltip>
                  </td>
                  <td>{{ val.goods }}</td>
                  <td>{{ val.goodstotal }}</td>
                  <td>
                    {{ val.total
                    }}<span v-if="val.refun_fee > 0"
                      >({{ $t("device.orders.status.refund_price") }}:{{
                        val.refun_fee
                      }})</span
                    >
                  </td>
                  <td>
                    <span v-if="val.payway == 0"
                      ><i class="ri-wallet-line"></i
                      >{{ $t("device.orders.payment.cash") }}</span
                    >
                    <span v-if="val.payway == 1"
                      ><i class="ri-wallet-line"></i
                      >{{ $t("device.orders.payment.wallet") }}</span
                    >
                    <span v-if="val.payway == 2"
                      ><i class="fab fa-alipay" style="color: #4dabf7"></i
                      >{{ $t("device.orders.payment.alipay") }}</span
                    >
                    <span v-if="val.payway == 3"
                      ><i class="fab fa-weixin" style="color: #3cb034"></i
                      >{{ $t("device.orders.payment.wxpay") }}</span
                    >
                    <span v-if="val.payway == 4"
                      ><i class="fab fa-apple-pay"></i
                      >{{ $t("device.orders.payment.applepay") }}</span
                    >
                    <span v-if="val.payway == 5"
                      ><i class="fab fa-google-wallet"></i
                      >{{ $t("device.orders.payment.googlewallet") }}</span
                    >
                    <span v-if="val.payway == 6"
                      ><i class="fab fa-amazon-pay"></i
                      >{{ $t("device.orders.payment.amazonpay") }}</span
                    >
                    <span v-if="val.payway == 7"
                      ><i class="fab fa-qq"></i
                      >{{ $t("device.orders.payment.qpay") }}</span
                    >
                    <span v-if="val.payway == 40"
                      ><i class="fab fa-bestpay"></i
                      >{{ $t("device.orders.payment.bestpay") }}</span
                    >
                    <span v-if="val.payway == 41"
                      ><i class="fab fa-sandpay"></i
                      >{{ $t("device.orders.payment.sandpay") }}</span
                    >
                  </td>
                  <td>
                    <label
                      class="badge badge-secondary"
                      v-if="val.paytime > 0 && val.refun_fee == 0"
                      >{{ $t("device.orders.status.orderpay") }}</label
                    >
                    <label class="badge badge-danger" v-if="val.paytime == 0">{{
                      $t("device.orders.status.nopay")
                    }}</label>
                    <label
                      class="badge badge-danger"
                      v-if="val.feedback == 6"
                      >{{ $t("device.orders.status.devicedown") }}</label
                    >
                    <label
                      class="badge badge-danger"
                      v-if="val.feedback == 2"
                      >{{ $t("device.orders.status.returnfalse") }}</label
                    >
                    <label
                      class="badge badge-info"
                      v-if="val.feedback == 3 && val.ED_id != 750004340001"
                      >{{ $t("device.orders.status.beersurpgoods") }}</label
                    >
                    <label
                      class="badge badge-warning"
                      v-if="
                        val.feedback == 7 &&
                        val.paytime > 0 &&
                        val.refun_fee == 0
                      "
                      >{{ $t("device.orders.status.serversenddone") }}</label
                    >
                    <label
                      class="badge badge-success"
                      v-if="
                        val.feedback == 1 &&
                        val.paytime > 0 &&
                        val.refun_fee == 0
                      "
                      >{{ $t("device.orders.status.pushdone") }}</label
                    >
                    <!--例外-->
                    <label
                      class="badge badge-success"
                      v-if="
                        val.feedback == 3 &&
                        val.paytime > 0 &&
                        val.refun_fee == 0 &&
                        val.ED_id == 750004340001
                      "
                      >{{ $t("device.orders.status.pushdone") }}</label
                    >

                    <label
                      class="badge badge-warning"
                      v-if="val.refun_fee > 0"
                      >{{ $t("device.orders.status.refuntrue") }}</label
                    >
                  </td>
                  <td v-if="val.paytime > 0">
                    {{ val.paytime | formatTimer }}
                  </td>
                  <td v-if="val.paytime == 0">
                    {{ val.addtime | formatTimer }}
                  </td>
                  <td>
                    <span :id="'orderid' + idx">{{ val.ED_id }}</span>
                    <b-tooltip :target="'orderid' + idx">{{
                      val.ED_name
                    }}</b-tooltip>
                  </td>
                  <td>
                    <a
                      class="btn btn-success rounded-pill"
                      v-if="
                        activeapp.indexOf('separate') >= 0 &&
                        val.sharing == 1 &&
                        val.payway > 1 &&
                        val.sharing_status == 0 &&
                        val.total >= 1 &&
                        val.refun_fee == 0 &&
                        jurisdiction.indexOf('orders-detail-separate') >= 0
                      "
                      @click="profitsharing(val.id, val.payway)"
                    >
                      {{ $t("device.orders.button.sharings") }}
                    </a>
                    <a
                      class="btn btn-success rounded-pill"
                      v-if="
                        activeapp.indexOf('separate') >= 0 &&
                        val.sharing == 1 &&
                        val.payway > 1 &&
                        val.sharing_status == 2 &&
                        jurisdiction.indexOf('orders-detail-separate') >= 0
                      "
                      @click="finishprofitsharing(val.id)"
                    >
                      {{ $t("device.orders.button.finishsharings") }}
                    </a>
                    <router-link
                      :to="{
                        path: '/device/orderdetail',
                        query: { id: val.id },
                      }"
                    >
                      <a
                        class="btn btn-info rounded-pill"
                        v-if="jurisdiction.indexOf('orders-detail-show') >= 0"
                      >
                        {{ $t("device.orders.button.info") }}
                      </a>
                    </router-link>
                    <a
                      class="btn btn-danger rounded-pill"
                      @click="
                        refun('order', val.sn, val.payway, val.total, '1')
                      "
                      v-if="
                        (val.forcerefund == 1 ||
                          (val.refund_button == 1 && val.payway > 1)) &&
                        jurisdiction.indexOf('orders-detail-refun') >= 0
                      "
                    >
                      {{ $t("device.orders.button.refun") }}
                    </a>
                    <a
                      class="btn btn-warning rounded-pill"
                      @click="logdata('order', val.id)"
                      v-if="
                        jurisdiction.indexOf('extend-logs-orderdata') >= 0 &&
                        val.paytime > 0
                      "
                    >
                      {{ $t("device.orders.button.logs") }}
                    </a>
                    <a
                      class="btn btn-dark rounded-pill"
                      @click="deletes('order', val.id)"
                      v-if="jurisdiction.indexOf('orders-detail-show') >= 0"
                    >
                      {{ $t("device.orders.button.delete") }}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>